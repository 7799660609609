var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      _c("AConfirmation", {
        attrs: { isShow: _vm.isPending },
        on: { cancel: _vm.cancelSubmit, confirm: _vm.submit },
      }),
      _vm.api.isLoading
        ? _c("v-skeleton-loader", { ref: "skeleton", attrs: { type: "table" } })
        : _vm._e(),
      _c("PageDeveloperUploadExcelSubscriptionHistory"),
      _c("ComponentUpdateVisionKey"),
      _c("ComponentDeveloperCompanyExceList"),
      _c("ComponentDeveloperCompanyDatabaseList"),
      _c("ComponentDeveloperCompanyMatched"),
      _c("ComponentDeveloperCompanyExcelUnmatched"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }