var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      _c("AConfirmation", {
        attrs: { isShow: _vm.isPending },
        on: { cancel: _vm.cancelSubmit, confirm: _vm.submit },
      }),
      _c("ASuccessWithoutReload", {
        attrs: { api: this.api },
        on: {
          close: function ($event) {
            return _vm.closeSuccesfulModal()
          },
        },
      }),
      _c(
        "v-row",
        [
          _c("v-col", { attrs: { align: "center", justify: "center" } }, [
            _c(
              "div",
              [
                _c(
                  "v-btn",
                  {
                    attrs: { color: "primary", loading: _vm.api.isLoading },
                    on: {
                      click: function ($event) {
                        return _vm.updateVisionKey()
                      },
                    },
                  },
                  [_vm._v(" Update Vision Key ")]
                ),
              ],
              1
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }